exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cambio-comercial-index-js": () => import("./../../../src/pages/cambio-comercial/index.js" /* webpackChunkName: "component---src-pages-cambio-comercial-index-js" */),
  "component---src-pages-cartoes-index-js": () => import("./../../../src/pages/cartoes/index.js" /* webpackChunkName: "component---src-pages-cartoes-index-js" */),
  "component---src-pages-chip-viagem-index-js": () => import("./../../../src/pages/chip-viagem/index.js" /* webpackChunkName: "component---src-pages-chip-viagem-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-internacional-index-js": () => import("./../../../src/pages/internacional/index.js" /* webpackChunkName: "component---src-pages-internacional-index-js" */),
  "component---src-pages-moedas-index-js": () => import("./../../../src/pages/moedas/index.js" /* webpackChunkName: "component---src-pages-moedas-index-js" */),
  "component---src-pages-seguro-viagem-index-js": () => import("./../../../src/pages/seguro-viagem/index.js" /* webpackChunkName: "component---src-pages-seguro-viagem-index-js" */)
}

